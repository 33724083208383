<template lang="pug">
.agentbase
  div( style="max-width: 25%; width: 100%; text-align: center;" v-if="preload")
      Loading
      h3 Conectando...
  div(v-show="!preload")
    transition(name="fade" mode="out-in")
      .container-logo(v-if="!turnId")
        img.stylelogo.d-block(:src="Logo" v-openlog)
        h5(
          class="container-text"
          style="position: absolute; margin-top: 30vh; text-align: center; color: firebrick;"
        ) La versión de aplicación utilizada por el cliente es menor a 4.0, por tanto, no se puede conectar a la videollamada. Por favor cierra esta ventana y finaliza el turno.
      Meeting(v-if="turnId" meetType="agent" :meetId="meetingId" @endMeet="endMeet" :username="username" @attendees="managePeople")
  div(class="styleDivData")
    DivInfoCall(meetType="agent")
    button(@click="onBlurVideo").buttonAgent {{ !isBlur ? "Apply blur" : "Remove blur"  }}
    input(@change="onSelectedFile" type="file")
    button(@click="onBlurVideo").buttonAgent {{ !isBlur ? "Apply bg image" : "Remove bg image"  }}
</template>

<script>
import _get from "lodash/get";
import { mapState, mapActions } from "vuex";
import Meeting from "./Meeting";
import LogoColmedica from "@/assets/colmedica_logo.png";
import DivInfoCall from "./components/DivInfoCall";
import Loading from "./components/Loading.vue";

export default {
  name: "AgentBase",

  components: {
    Meeting,
    DivInfoCall,
    Loading
  },

  data: () => ({
    LogoColmedica,
    preload: true
  }),

  watch: {
    turnId(val) {
      try {
        let font = _get(this.c_data, "fu", "");
        font = !font || font == "" ? "ad" : font;
        let trackingData = {
          uuid: window.person.id,
          accion: "agent-turnId-notExist-CanceledAgent",
          debmedia_turn_code: this.turn?.code,
          url_origen: window.location.href,
          origen: font,
          mensajes: ""
        };
        this.sendTracking(trackingData);
      } catch (error) {
        console.log(error)
      }
      if (!val) this.$router.push({ name: "CanceledAgent" });
    }
  },

  created() {
    setTimeout(() => {
      if (!this.turnId) {
        location.reload();
      }
    }, 3000);
  },

  computed: {
    ...mapState({
      turn: state => state.turn,
      env: state => state.env,
      turnCoorelation: state => state.turnCoorelation,
      isBlur: state => state.meeting.isBlur,
      c_data: state => state.client_data,
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    },

    username() {
      return (
        `${this.turn.worker?.actualUser?.uUser?.firstName || ""} ${this.turn
          .worker?.actualUser?.uUser?.lastName || ""}`.trim() || "Agente"
      );
    },

    meetingId() {
      return this.turnCoorelation?.code_title;
    },

    turnId() {
      return _get(this.turn, "code");
    }
  },

  methods: {
    ...mapActions({
      cancelTurn: "cancelTurn",
      verify_client_data: "verify_client_data",
      stopCheck: "recording/stopCheck",
      startBlurVideo: "meeting/startBlurVideo",
      removeBlurVideo: "meeting/removeBlurVideo",
      sendTracking: "virtualrow/handleTracking",
    }),

    async endMeet() {
      console.error("Se finaliza la llamada");
      this.stopCheck();
      await this.cancelTurn({ local: true });
    },

    managePeople(val) {
      this.preload = val;
    },

    onBlurVideo() {
      if (!this.isBlur) {
        this.startBlurVideo();
        return;
      }

      this.removeBlurVideo();
    },

    onSelectedFile(file) {
      console.warn({ file });
    }
  }
};
</script>

<style scoped>
.agentbase {
  height: 100vh;
  width: 100%;
}

.styleDivData {
  width: 300px;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 5;
  left: 30px;
  top: 0;
}
.container-text {
  width: calc(100% - 600px - 30px);
}
@media only screen and (max-width: 866px) {
  .styleDivData {
    top: auto;
    left: auto;
    position: initial;
    display: inline;
    left: 30px;
    top: 0;
  }
  .container-text {
    width: calc(100% - 40px);
  }
}
</style>
